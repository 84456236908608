import ZingChart from 'zingchart-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import './widget.scss';

export default function Widget({
  data,
  unit,
  text,
  color,
  backgroundColor,
  customRules = [],
}) {
  const chartConfig = {
    gui: {
      contextMenu: {
        empty: true,
        button: {
          visible: false,
        },
      },
    },
    graphset: [
      {
        type: 'area',
        backgroundColor: 'transparent',
        plot: {
          aspect: 'spline',
          lineWidth: '2px',
          alphaArea: '.3',
          marker: { visible: false },
          tooltip: { visible: false },
        },
        plotarea: {
          adjustLayout: true,
          marginBottom: 'dynamic',
          marginLeft: 'dynamic',
          marginRight: 'dynamic',
          marginTop: '10%',
        },
        crosshairX: {
          scaleLabel: {
            text: '%v',
          },
        },
        scaleX: {
          itemsOverlap: true,
          lineWidth: '0px',
          values: data.map((c) =>
            new Date(c.date).toLocaleTimeString('nl-BE', {
              hour: 'numeric',
              minute: 'numeric',
            })
          ),
          labels: data.map((c) => {
            if (new Date(c.date).getMinutes() === 0) {
              return new Date(c.date).toLocaleTimeString('nl-BE', {
                hour: 'numeric',
                minute: 'numeric',
              });
            }
            return undefined;
          }),
          maxLabels: 50,
          //maxLabels: consumption.length,
          guide: {
            visible: false,
          },
          tick: {
            visible: false,
          },
        },
        scaleY2: {
          lineWidth: '0px',
          guide: {
            alpha: 0.1,
          },
          tick: {
            visible: false,
          },
        },
        series: [
          {
            values: data.map((c) => c.value),
            text: `${text} (${unit})`,
            scales: 'scale-x, scale-y-2',
            lineColor: color,
            backgroundColor: backgroundColor,
            rules: customRules,
          },
        ],
      },
    ],
  };

  return (
    <div className="Widget">
      <div className="WidgetValue">
        <div className="ValueBox" style={{ color }}>
          <FontAwesomeIcon size={'xl'} icon={faArrowDown} />
          <div className="Value">
            <span>3.4</span>
            <span className="Unit">{unit}</span>
          </div>
        </div>
        <div className="SubScript">{text}</div>
      </div>

      <ZingChart data={chartConfig} height="300" />
    </div>
  );
}
