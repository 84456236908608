import { AxiosResponse } from 'axios';
import { useState } from 'react';

const useApi = <T>(
  apiFunc: (...args: any[]) => {
    request: Promise<AxiosResponse<T, any>>;
    cancel: () => void;
  }
) => {
  const [data, setData] = useState<T>();
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(false);

  async function request(...args: any[]) {
    setLoading(true);
    const { request, cancel } = apiFunc(...args);

    try {
      const response = await request;
      setData(response.data);
      setError('');
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
    return () => cancel();
  }

  return {
    data,
    error,
    loading,
    request,
  };
};

export default useApi;
