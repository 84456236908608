import Widget from '../widget/widget';
import useApi from '../../hooks/useApi';
import electricityApi from '../../api/electricity';
import { useEffect } from 'react';

function Solar({ start, end }: { start: Date; end: Date }) {
  const { data, request } = useApi(electricityApi.production);

  useEffect(() => {
    request(start, end);
  }, [start, end]);

  const unit = 'kWh';
  const text = 'Zonnepanelen';
  const color = '#0cd32a';
  const backgroundColor = '#095146 #0cd32a';

  return (
    <Widget data={data || []} {...{ unit, text, color, backgroundColor }} />
  );
}

export default Solar;
