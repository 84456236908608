import apiClient from './api-client';

export class HttpService<T, U = void> {
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  get(params?: { [key: string]: string | number }) {
    const controller = new AbortController();
    let paramString = '';
    if (params) {
      paramString =
        '?' +
        Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
    }
    const request = apiClient.get<T[]>(`${this.endpoint}${paramString}`, {
      signal: controller.signal,
    });
    return { request, cancel: () => controller.abort() };
  }

  post(data: T) {
    const controller = new AbortController();
    const request = apiClient.post<U>(this.endpoint, data, {
      signal: controller.signal,
    });
    return { request, cancel: () => controller.abort() };
  }
}

const create = <T, U = void>(endpoint: string) =>
  new HttpService<T, U>(endpoint);

export default create;
