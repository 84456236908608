import create from '../services/http-service';

function consumption(start: Date, end: Date) {
  return create('electricity/consumption').get({
    start: start.toISOString(),
    end: end.toISOString(),
  });
}

function production(start: Date, end: Date) {
  return create('electricity/production').get({
    start: start.toISOString(),
    end: end.toISOString(),
  });
}

const exports = { consumption, production };
export default exports;
