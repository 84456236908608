import create from '../services/http-service';

function consumption(start: Date, end: Date) {
  return create('gas').get({
    start: start.toISOString(),
    end: end.toISOString(),
  });
}

const exports = { consumption };
export default exports;
