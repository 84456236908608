import { JwtPayload, jwtDecode } from 'jwt-decode';

const key = 'authToken';

function storeToken(authToken: string): void {
  try {
    sessionStorage.setItem(key, authToken);
  } catch (error) {
    console.log('Error storing the auth token', error);
  }
}

function getToken(): string | null {
  try {
    return sessionStorage.getItem(key);
  } catch (error) {
    console.log('Error getting the auth token', error);
    return null;
  }
}

function getUser(): JwtPayload | null {
  const token = getToken();
  return token ? jwtDecode(token) : null;
}

function removeToken() {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.log('Error removing the auth token', error);
  }
}

const exports = { storeToken, removeToken, getToken, getUser };
export default exports;
