import create from '../services/http-service';

function login(username: string, password: string) {
  return create<{ username: string; password: string }, string>('/auth').post({
    username,
    password,
  });
}

const exports = { login };
export default exports;
