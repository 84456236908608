import useApi from '../../hooks/useApi';
import Widget from '../widget/widget';
import electricityApi from '../../api/electricity';
import { useEffect } from 'react';

function EnergyTotal({ start, end }: { start: Date; end: Date }) {
  const { data, request } = useApi(electricityApi.consumption);

  useEffect(() => {
    request(start, end);
  }, [start, end]);

  const unit = 'kWh';
  const text = 'Stroom totaal';
  const color = '#7343f2';
  const backgroundColor = '#7343f2 #1e0b5a';
  const customRules = [
    {
      rule: '%v < 0',
      lineColor: '#0cd32a',
      backgroundColor: '#095146 #0cd32a',
    },
  ];

  return (
    <Widget
      data={data || []}
      {...{ unit, text, color, backgroundColor, customRules }}
    />
  );
}

export default EnergyTotal;
