import Widget from '../widget/widget';
import { useEffect } from 'react';
import useApi from '../../hooks/useApi';
import gasApi from '../../api/gas';

function Gas({ start, end }: { start: Date; end: Date }) {
  const { data, request } = useApi(gasApi.consumption);

  useEffect(() => {
    request(start, end);
  }, [start, end]);

  const unit = 'm³';
  const text = 'Gas';
  const color = '#f10b71';
  const backgroundColor = '#f10b71 #5a0b2a';

  return (
    <Widget data={data || []} {...{ unit, text, color, backgroundColor }} />
  );
}

export default Gas;
