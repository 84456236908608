import './App.scss';
import Dashboard from './screens/Dashboard/Dashboard';
import Login from './screens/Login/Login';
import { useEffect, useState } from 'react';
import authStorage from './auth/storage';
import AuthContext from './auth/context';
import { JwtPayload } from 'jwt-decode';

function App() {
  const [user, setUser] = useState<JwtPayload>();
  const [isReady, setIsReady] = useState(false);

  // Restore user
  useEffect(() => {
    try {
      const user = authStorage.getUser();
      if (user) setUser(user);
    } catch (error) {
      console.log('Error restoring user', error);
    } finally {
      setIsReady(true);
    }
  }, []);

  if (!isReady) return null;

  return (
    <div className="App">
      <AuthContext.Provider value={{ user, setUser }}>
        {user ? <Dashboard /> : <Login />}
      </AuthContext.Provider>
    </div>
  );
}

export default App;
