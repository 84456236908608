import DateSelector from '../../components/dateSelector/dateSelector';
import EnergyTotal from '../../components/energyTotal/energyTotal';
import Gas from '../../components/gas/gas';
import Solar from '../../components/solar/solar';
import useDateSelector from '../../hooks/useDateSelector';
import './Dashboard.scss';

function Dashboard() {
  const { start, end, periodLabel, handleUpdateDate } = useDateSelector();

  return (
    <>
      <header>
        <h1>{periodLabel}</h1>
        <hr />
      </header>

      <div className="widgets">
        <EnergyTotal start={start} end={end} />
        <Gas start={start} end={end} />
        <Solar start={start} end={end} />
      </div>

      <div className="bottomSpacer"></div>
      <div className="selectorContainer">
        <DateSelector label={periodLabel} onUpdate={handleUpdateDate} />
      </div>
    </>
  );
}

export default Dashboard;
