import { useState } from 'react';

const useDateSelector = () => {
  const initialStart = new Date();
  initialStart.setHours(0, 0, 0, 0);
  const initialEnd = new Date();
  initialEnd.setHours(24, 0, 0, 0);

  const [start, setStart] = useState(initialStart);
  const [end, setEnd] = useState(initialEnd);
  const [periodLabel, setPeriodLabel] = useState<string>('Vandaag');

  function handleUpdateDate(
    add: number,
    period: 'day' | 'week' | 'month' | 'year'
  ) {
    let newStart: Date = new Date();
    let newEnd: Date = new Date();
    switch (period) {
      case 'day':
        newEnd = new Date(end);
        newEnd.setDate(newEnd.getDate() + add);
        newStart = new Date(newEnd);
        newStart.setDate(newStart.getDate() - 1);

        setPeriodLabel(getDayLabel(newStart));
        break;
      case 'week':
        // Todo: implement week logic
        break;
      case 'month':
        // Todo: implement month logic
        break;
      case 'year':
        // Todo: implement year logic
        break;
    }

    setStart(newStart);
    setEnd(newEnd);
  }

  function getDayLabel(date: Date): string {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (date.getTime() === today.getTime()) return 'Vandaag';
    if (date.getTime() === today.getTime() - days(1)) return 'Gisteren';
    if (date.getFullYear() === today.getFullYear())
      return date.toLocaleDateString('nl-BE', {
        weekday: 'short',
        day: 'numeric',
        month: 'long',
      });

    return date.toLocaleDateString('nl-BE', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  function days(numDays: number): number {
    return 24 * 60 * 60 * 1000 * numDays;
  }

  return { start, end, periodLabel, handleUpdateDate };
};

export default useDateSelector;
