import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import './dateSelector.scss';

interface Props {
  label: string;
  onUpdate: (add: number, period: 'day' | 'week' | 'month' | 'year') => void;
}

function DateSelector({ label, onUpdate }: Props) {
  const [period, setPeriod] = useState<'day' | 'week' | 'month' | 'year'>(
    'day'
  );

  const periods: { label: string; value: 'day' | 'week' | 'month' | 'year' }[] =
    [
      {
        label: 'Dag',
        value: 'day',
      },
      {
        label: 'Week',
        value: 'week',
      },
      {
        label: 'Maand',
        value: 'month',
      },
      {
        label: 'Jaar',
        value: 'year',
      },
    ];

  return (
    <div className="DateSelector">
      <div className="day">
        <button onClick={() => onUpdate(-1, period)}>
          <FontAwesomeIcon size={'lg'} icon={faChevronLeft} />
        </button>
        <div>{label}</div>
        <button onClick={() => onUpdate(1, period)}>
          <FontAwesomeIcon size={'lg'} icon={faChevronRight} />
        </button>
      </div>
      <div className="period">
        {periods.map((p) => (
          <button
            key={p.value}
            onClick={() => {
              setPeriod(p.value);
              onUpdate(0, p.value);
            }}
            className={period === p.value ? 'active' : ''}
          >
            {p.label}
          </button>
        ))}
      </div>
    </div>
  );
}

export default DateSelector;
