import axios from 'axios';
import authStorage from '../auth/storage';

export { CanceledError } from 'axios';

const apiClient = axios.create({
  baseURL: '/api',
});

// Insert auth token in request headers
apiClient.interceptors.request.use(
  (config) => {
    const token = authStorage.getToken();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Handle 401 Unauthorized errors
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      authStorage.removeToken();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default apiClient;
