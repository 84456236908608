import useApi from '../../hooks/useApi';
import authApi from '../../api/auth';
import './Login.scss';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../auth/useAuth';

function Login() {
  //const history = useNavigate();
  const { logIn } = useAuth();
  const { data: token, error, request: authenticate } = useApi(authApi.login);

  async function submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const form = event.currentTarget;
    const username = form.username.value;
    const password = form.password.value;
    const cancel = await authenticate(username, password);
    cancel();
  }

  useEffect(() => {
    if (token) logIn(token);
  }, [token, logIn]);

  return (
    <div className="Login">
      <div className="container">
        <div className="header">
          <h1>Login</h1>
        </div>
        <form action="POST" className="content" onSubmit={submitForm}>
          {error && <div className="error">Inloggen mislukt...</div>}
          <div className="form-group">
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Username"
            />
            <div className="focus"></div>
          </div>
          <div className="form-group">
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
            />
            <div className="focus"></div>
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
