import { jwtDecode } from 'jwt-decode';
import { useContext } from 'react';

import AuthContext from './context';
import authStorage from './storage';

export default function useAuth() {
  const { user, setUser } = useContext(AuthContext) as any;

  function logOut() {
    setUser(null);
    authStorage.removeToken();
  }

  function logIn(token: string) {
    const user = jwtDecode(token);
    setUser(user);
    authStorage.storeToken(token);
  }

  return { user, setUser, logOut, logIn };
}
